import React from "react";
import styles from './related-posts.scss'
import {Link} from "gatsby";
import {blogUrl, postUrl} from "../../helpers";
import { BsArrowRight } from "react-icons/bs";

class RelatedPosts extends React.Component {
  constructor(props) {
    super(props)
    this.relatedPosts = this.props.relatedPosts
    this.title = this.props.title || 'Related Posts'
    this.dataAttributes = this.props.dataAttributes || {}
  }

  render() {
    return (
      <div className={`popular-posts-container ${this.props.containerClassName}`}>
        <h2>{this.title}</h2>
        <ul className="row">
          { this.relatedPosts.map((post, index) => {
            return (
              <li className="col-12 col-md-6 text-left" key={index}>
                <Link to={postUrl(post)} className="d-flex align-items-center" {...this.dataAttributes} title="Read Post">{post.title}</Link>
              </li>
            )
          })}
        </ul>
        <Link to={blogUrl()} className="more-articles-btn btn btn-outline mt-4 mt-md-0" title="Read More Articles">More Articles <BsArrowRight title="Arrow Right"/></Link>
      </div>
    )
  }
}

export default RelatedPosts
