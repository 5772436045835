import React, {useEffect} from 'react'
import {graphql, Link} from 'gatsby'
import { get, capitalize, kebabCase, startCase} from 'lodash'
import BaseModal from '../components/modals/base-modal'
import Layout from '../components/theme/layout'
import Header from '../components/theme/header'
import TableOfContentsV2 from '../components/table-of-contents/table-of-contents-v2'
import SEO from '../components/seo'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import defaultRTRenderOptions from "../../contentful/rich-text-renderer-options"
import { pageUrl, postUrl, blogUrl, parseContentfulShopifyProduct, getHeaderSize, buildBreadcrumbsItem, authorUrl,
  collectionUrl, createTableOfContentsStructureFromContentful, productUrl, findAncestorByClassNameInEventPath,
  htmlFromText, colorToClass, isHighSpeedConnection, isExternalUrlOrHash, isHash } from "../helpers.js"
import styles from '../scss/post.scss'
import loadable from '@loadable/component'
import Footer from "../components/theme/footer";
import StickyShare from "../components/sticky-share/sticky-share";
import RelatedPosts from '../components/related-posts/related-posts'
import Img from "gatsby-image";
import { FaArrowRight } from "react-icons/fa";
import Accordion from "../components/accordion/accordion";

const DosageCalculator = loadable(() => (import('../components/dosage-calculator/dosage-calculator')))

const ContentModal = ({relatedPosts, relatedProducts}) => {
  return (
    <div className='content-modal row new-blog-style'>
      <div className="col-12 col-lg-8 mx-auto">
        { relatedPosts ? (
          <div className="mt-3 mb-5">
            {relatedPosts.map(( post, index ) => {
              return <div className="my-4 d-flex align-items-center" key={index}>
                <a
                  href={postUrl(post)}
                  target="_top"
                  className="col-12 p-0 mb-2 related-posts-link d-flex align-items-center" 
                  data-event-trigger="click"
                  data-event-name="Recommendation Clicked"
                  data-event-cta="Blog Posts related to this article"
                  data-event-category="direct-link"
                  data-event-label={post.title}
                  data-event-uri={postUrl(post)}>
                  <div className="col-10 pl-0">{post.title}</div>
                  <div className="col-2 d-flex justify-content-end"><FaArrowRight title="Arrow Right"/></div>
                </a>
              </div>
            })}
          </div>
        ) : <div>There are no related posts to this article</div> }

        { relatedProducts ? (
          <>
            <div className="subtitle pt-2">Products related to this article</div>
            <div className="row d-flex related-products">
              {relatedProducts.map((product, i ) => {
                return 
                  <a
                    href={productUrl(product)}
                    target="_top"
                    className="col-6 col-lg-4" 
                    key={i}
                    data-event-trigger="click"
                    data-event-name="Recommendation Clicked"
                    data-event-cta="Products related to this article"
                    data-event-category="direct-link">
                    <div className="image-container mx-auto align-items-center">
                      <img src={product.images[0].transformedSrc || product.images[0].src} alt={product.images[0].altText || product.handle} />
                    </div>
                    <div className="text-center">{product.title}</div>
                  </a>
              })}
            </div>
          </>
        ) : ''}
      </div>
    </div>
  )
}

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.buildClusterBreadcrumb = get(this.props, 'pageContext.build_cluster_breadcrumb', null)
    this.post = get(this.props, 'data.contentfulBlogPost')
    this.author = get(this.props, 'data.author')

    if (this.buildClusterBreadcrumb) {
      // Custom code for build different breadcrumbs for cluster blog posts
      if (this.post.previousBreadcrumb) {
        this.breadcrumbs = [
          buildBreadcrumbsItem(capitalize(this.post.cluster), pageUrl(this.post.previousBreadcrumb, this.props.location.origin)),
          buildBreadcrumbsItem(this.post.title, this.props.location.href)
        ]
      } else {
        this.breadcrumbs = [
          buildBreadcrumbsItem(capitalize(this.post.cluster), pageUrl(kebabCase(this.post.cluster), this.props.location.origin)),
          buildBreadcrumbsItem(this.post.title, this.props.location.href)
        ]
      }
      // End of custom code
    } else {
      this.breadcrumbs = [
        buildBreadcrumbsItem('Blog', blogUrl(null, 1, this.props.location.origin)),
        buildBreadcrumbsItem(capitalize(this.post.category), blogUrl(this.post.category, 1, this.props.location.origin)),
        buildBreadcrumbsItem(this.post.title, this.props.location.href)
      ]
    }

    this.publisherName = get(this, 'props.data.site.siteMetadata.publisher.name', '') || ''
    this.contentfulNoIndexBlogs = get(this.props, 'data.site.siteMetadata.contentfulNoIndexBlogs', [])
    this.ogImage = get(this.post, 'shareImage.fixed') || get(this.post, 'heroImage.fixed')
    this.post.seoTitle = this.post.seoTitle.replace(/Honest Paws$/, 'Honest Paws®')

    this.seo = {
      description: this.post.metaDescription,
      title: this.post.seoTitle,
      meta: {
        title: this.post.seoTitle,
        image: this.ogImage,
        ogMeta: {
          'og:site_name': this.publisherName,
          'og:type': 'article',
          'article:author': this.author.name,
          'article:published_time': this.post.formattedCreatedDate,
          'article:modified_time': this.post.formattedUpdatedDate
        }
      },
      children: [],
      // TODO - remove this after test
      disallowIndexing: this.post.disallowIndexing || false
    }

    this.anchorsOffset  = 120

    this.state = {
      active_section: 0,
      show_background_image: false
    }

    this.url = postUrl(this.post, this.props.data.site.siteMetadata.siteUrl)
    this.relatedProducts = get(this.post, 'relatedProducts', null)
    this.defaultImage = get(this.props, 'data.defaultImage.childImageSharp.fluid')

    this.discountBoxTitle = "Try Honest Paws CBD Oil Today!"
    this.discountBoxTestimonial = ["“Honest paws has done wonders for my old girl. her back legs are not shaking as much and she wants to play. I have tried many products but this one has done what the others did not.” - ", <b>Kim B.</b>]
    this.discountBoxCTAText = "Get 15% Off CBD Oil for Dogs Use Promo Code: TRYCBD"
    this.discountBoxCTAUrl = "/products/relief-tincture/"
    this.discountBoxMessage = [<b>100% Satisfaction Guarantee.</b>]

    this.klaviyoFormId = "klaviyo-form-XBG29B"

    if (this.contentfulNoIndexBlogs.indexOf(this.post.category) >= 0 ) {
      this.seo.disallowIndexing = true
    }

    this.tocNodesTree = createTableOfContentsStructureFromContentful(this.post.body.json.content)

    this.modalContent = null
    this.displayContent = this.displayContent.bind(this)
  }

  /**
   * Displays content inside a modal
   * @param  {event} e
   * @return {void}
   */
  displayContent(e) {
    e.preventDefault();
    this.modalContent.showModal()
  }

  componentDidMount() {
    if (isHighSpeedConnection()) {
      this.setState({
        show_background_image: true
      });
    }
  }

  render() {
    const options = {
      ...defaultRTRenderOptions
    }
    
    const allowedTagsForRendering = ['br', 'p', 'li', 'ul', 'a']

    let headerOptions = {
      location: this.props.location,
      hidecart: true
    }

    let footerProps = {}

    // Compliant header and footer
    if (this.post.compliantHeaderFooter){
      headerOptions.menu = null
      headerOptions.hidelinkonlogo = true

      footerProps.hidelinkonlogo= true
      footerProps.hidelinks= true
      footerProps.menu= null
    }

    const footer = (<Footer { ...footerProps } />)
    const header = (<Header { ...headerOptions } />)

    if (this.post.canonicalUrl) {
      this.seo.children.push(<link rel="canonical" href={ this.post.canonicalUrl } key='canonical-url'  />)
    }
    if (this.post.schemaOrgJson) {
      this.seo.children.push(<script type="application/ld+json" key='structured-data'>{ this.post.schemaOrgJson.internal.content }</script>)
    }
    
    const dataAttributesForRecommendationsHeader = {
      'data-event-trigger': 'click',
      'data-event-name': 'Recommendation Clicked',
      'data-event-cta': 'Blog Top Header',
      'data-event-category': 'direct-link',
    };

    const dataAttributesForRelatedPosts = {
      'data-event-trigger': 'click',
      'data-event-name': 'Recommendation Clicked',
      'data-event-cta': 'You May Also Like Link',
      'data-event-category': 'direct-link'
    };
    
    return (
      <Layout containerClassName={`post ${this.post.slug} post-${this.post.contentful_id} breadcrumbs-ccc`}
          location={ this.props.location } hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo }></SEO>
        { this.post.showShareComponent && <StickyShare shareDialogTitle={this.seo.title} shareDialogText={this.seo.description}/> }

        {/* Banner and Title Section */}
        <div className={`blog-post-title ${this.state.show_background_image && kebabCase(this.post.category)}`} >
          <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-lg-6 pb-lg-4 py-0">
            <h1 itemProp="headline name mt-5">{ this.post.title }</h1>
          </div>
        </div>

        
        {/* End Banner and Title Section */}

        <div className={`container new-blog-style ${this.post.fullWidth ? 'blog-full-width' : ''}`}>
          <article className="post-content-container px-0">
            <meta itemProp="url" content={ this.url } />
            <link itemProp="mainEntityOfPage" href={ this.url } />
            <meta itemProp="datePublished" content={ this.post.formattedCreatedDate } />
            <meta itemProp="dateModified" content={ this.post.formattedUpdatedDate } />
            <meta itemProp="headline" content={ this.post.title } />
            { this.author && <link itemProp="author" href={authorUrl(this.author, this.props.location.origin)} /> }
            { this.publisherName && <link itemProp="publisher" href={ `#${this.publisherName.replace(' ', '')}Org` } /> }
            <meta itemProp="image" content={ this.post.heroImage ? this.post.heroImage.fixed.src : this.defaultImage.src } />
            <div className="row">              
              <div className={`about-hp-description col-12 mx-auto ${this.post.fullWidth ? 'col-md-10' : 'col-md-9'}`}>
               <img src={ this.props.data.logoImage.childImageSharp.fixed.src } srcSet={ this.props.data.logoImage.childImageSharp.fixed.srcSet } alt="Honest Paws Logo" width="50" height="50"/>
               <p className="mision-text">
                 Honest Paws produces high quality <a target="_top" href="https://www.honestpaws.com/collections/cbd-oil-for-dogs/" {...dataAttributesForRecommendationsHeader} >CBD Oil for Dogs</a>, <a target="_top" href="https://www.honestpaws.com/collections/cbd-dog-treats/" {...dataAttributesForRecommendationsHeader} >CBD Dog Treats</a>, <a target="_top" href="https://www.honestpaws.com/products/cbd-oil-for-cats/" {...dataAttributesForRecommendationsHeader} >CBD Oil for Cats</a> and <a target="_top" href="https://www.honestpaws.com/blog/probiotics-for-dogs/" {...dataAttributesForRecommendationsHeader} >Probiotics For Dogs</a>.
               </p>
              </div>
              {/*If is needed for full width blog cases*/}
              <div className={`col-12 mx-auto ${this.post.fullWidth ? 'col-md-10' : 'col-md-9'}`}>

                {/*New Author Info Container*/}
                <div className="writer-info align-items-center d-flex justify-content-between w-100">
                  <div className="d-flex align-items-center">                  
                    <a href={`https://www.honestpaws.com/authors/${kebabCase(this.author.name)}`} rel="author" target="_blank" className="blog-author">
                      <img className="author-image" src={ this.author.image.fixed.src } srcSet={ this.author.image.fixed.srcSet } alt={ this.author.name } width="44" height="44" />
                      {this.author.name} {this.author.title}
                    </a>&nbsp;|&nbsp;{ this.post.formattedUpdatedDate }
                  </div>
                  <span><span className="dot"></span>&nbsp;&nbsp;{ startCase(this.post.category) }</span>
                </div>
                {/* End New Author Info Container*/}

                {/* Sumary option */}
                {this.post.summary ? (
                  <div className="post-summary d-none" itemProp="description">
                    { documentToReactComponents(this.post.summary.json, options) }
                  </div>
                ) : '' }

                {/* Principal Section */}
                <div className="post-content" itemProp="articleBody">
                  { this.post.bottomLine && (
                    <div className="bottom-line-container">
                      <h3 className="mb-3">The Bottom Line:</h3>
                      <div className="row">
                        { this.post.bottomLine.line1 && (<div className="col-12 col-md-9" dangerouslySetInnerHTML={ htmlFromText(this.post.bottomLine.line1.childMarkdownRemark.html, allowedTagsForRendering) }></div>) }
                        { this.post.bottomLine.cta && (
                          <div className="col-8 col-md-3 text-md-center">
                            <a target="_blank" href={this.post.bottomLine.cta.url} 
                                className={`btn ${colorToClass(this.post.bottomLine.cta.color, 'btn-', 'btn-primary')}`}
                                data-event-trigger="click"
                                data-event-name="Recommendation Clicked"
                                data-event-cta="Bottom Line"
                                data-event-category="direct-link"
                                data-event-element="anchor"
                                data-event-color="rgb(251, 142, 30)">
                              { this.post.bottomLine.cta && this.post.bottomLine.cta.extraAttributes && this.post.bottomLine.cta.extraAttributes.img && <img src={this.post.bottomLine.cta.extraAttributes.img} alt={this.post.bottomLine.cta.label} /> } 
                              {this.post.bottomLine.cta.label}
                            </a>
                          </div>
                        ) }
                        <hr className="w-100"/>
                        { this.post.bottomLine.line2 && (<div className="col-12 col-md-9" dangerouslySetInnerHTML={ htmlFromText(this.post.bottomLine.line2.childMarkdownRemark.html, allowedTagsForRendering) }></div>) }
                      </div>
                    </div>
                  )}

                  { this.tocNodesTree.length > 0 &&
                    <TableOfContentsV2 nodesTree={this.tocNodesTree}/>
                  }

                  {/* Dosage Calculator option */}
                  { this.post.showDosageCalculator ? (
                      <DosageCalculator fullWidth={true}/>
                    ) : ''
                  }

                  {/* Main Content */}
                  { documentToReactComponents(this.post.body.json, options) }

                  {/* SOURCES */}
                  {this.post.sources ? (
                        <Accordion items={[{
                          header: 'Sources',
                          content: this.post.sources,
                        }]} className='header-primary-transparent blog-post-sources-section' />
                    ) : ''
                  }

                </div>
                {/* End Principal Section */}

                { this.relatedProducts ? (
                  <>
                    <div className="related-products-subtitle py-3">Products related to this article</div>
                    <div className="col-12 d-flex related-products justify-content-between justify-content-lg-start p-0">
                      {this.relatedProducts.map(parseContentfulShopifyProduct).map((product, i ) => {
                        return <a target="_top" href={`https://www.honestpaws.com/products/${product.handle}`} className="mr-lg-4 mb-4" key={i}>
                          <div className="image-container d-flex align-items-center justify-content-center">
                            <img src={product.images[0].transformedSrc || product.images[0].src} alt={product.images[0].altText || product.handle} loading="lazy" />
                          </div>
                          <div className="text-center text-primary">{product.title}</div>
                        </a>
                      })}
                    </div>
                  </>
                ) : ''}
              </div>
              { this.post.relatedPosts &&
                <RelatedPosts relatedPosts={this.post.relatedPosts} dataAttributes={dataAttributesForRelatedPosts} containerClassName="col-12 col-lg-9 mx-auto" title="You May Also Like" />
              }
              <div className="bg-light col-12 mx-auto col-md-9 author-bio">
                <div className="author-info">
                  <Img fluid={this.author.image.fluid} className="rounded-circle author-img" loading="lazy" alt={ `${this.author.name}${this.author.title && `, ${this.author.title}` || ''}` } />
                  <a className="author-name" target="_top" href={`https://www.honestpaws.com/authors/${kebabCase(this.author.name)}`} rel="author">
                    <span className="strong">{ `${this.author.name}${this.author.title && `, ${this.author.title}` || ''}` }</span>
                    <br/>
                    <span>
                      { this.author.role && this.author.role.map((role, i) => {
                        role = capitalize(role)
                        return (i === 0 && role) || (i === this.author.role.length - 1 && ` and ${role}`) || `, ${role}`
                      }) }
                    </span>
                  </a>
                </div>
                <p> { get(this.author, 'shortBio.childMarkdownRemark.internal.content') } </p>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!, $authorId: String) {
    site {
      siteMetadata {
        publisher {
          name
        }
        title, 
        siteUrl,
        contentfulNoIndexBlogs
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      ...contentfulBlogPostWithoutAuthor
    }
    author: contentfulPerson (id: {eq: $authorId}) {
      ...contentfulPersonNoImage
      image {
        fixed(width:44, height: 44) {
          ...contentfulImageFixed
        }
        fluid(maxWidth:80, maxHeight: 80) {
          ...contentfulImageFluid
        }
      }
    }
    defaultImage: file(relativePath: { eq: "og-default-3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoImage: file(relativePath: { eq: "honestpaws-logo-circle-small.png" }) {
      childImageSharp {
        fixed(height: 50, width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
