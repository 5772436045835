import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import {get, omit} from 'lodash'
import Helmet from 'react-helmet'
import FooterNavigation from './footer-navigation'
import footer from './footer.scss'
import hpLogo from "../../../static/img/honestpaws-logo-white-new.png"

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.footerMenu = get(props, 'data.footerMenu')
    this.socialNetworks = get(props, 'data.site.siteMetadata.socialNetworks', {}) || {}
  }
  
  render() {    
    const { data } = this.props   
    const certapetUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.getStarted')}`
    
    const props = omit(this.props, ['data'])
    
    return (
      <footer { ...props }>
        <FooterNavigation menu={'menu' in props === false ? this.footerMenu : props.menu}
                          socialNetworks={ this.socialNetworks }
                          logo={ hpLogo }
                          hideLinks={props.hidelinks}
                          hideLinkOnLogo={props.hidelinkonlogo} />
      </footer>
    )
  }
}



export default (props) => {  
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          certapetInfo {
            prices {
              single
              combo
            }
            url {
              getStarted
            }
          }
          socialNetworks {
            instagram
            facebook
            pinterest
            twitter
            youtube
          }
        }
      }
      # footerLogo: file(relativePath: { eq: "honestpaws-logo-white-new.png" }) {
      #   childImageSharp {
      #     fluid(maxHeight: 45) {
      #       ...GatsbyImageSharpFluid
      #     }
      #     fixed(height: 45) {
      #       ...GatsbyImageSharpFixed
      #     }
      #   }
      # }    
      footerMenu: contentfulMenu( name: {eq: "Footer Menu"} ) {
        ...contentfulMenu
      }
    }
  `)
  
  return (
    <Footer  {...props} data={data} />
  )
};
