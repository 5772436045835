import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { get } from 'lodash'
import MenuColumnList from '../menu-column-list/menu-column-list'
import { FaInstagram, FaFacebookSquare, FaTwitter, FaPinterest, FaYoutube } from 'react-icons/fa';
import { isExternalUrlOrHash, slashLink, extractValueFromAttributes } from "../../../src/helpers.js"
import CustomIframe from '../../components/iframe/iframe'
import styles from "./footer-navigation.scss"

class FooterNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.klaviyoFormId = 'email_signup'
    this.klaviyoFormIdMobile = 'email_signup_mobile'
    this.klaviyoTitle = 'Sign up for Honest Paws emails:'
    this.socialNetworks = get(props, 'socialNetworks', {}) || {}
  }

  /**
   * Renders menu from contentful
   * @param  {Object} menu
   * @return {HTMLDomElement}
   */
  renderMenuFromContentful(menu) {
    const menuId = extractValueFromAttributes(menu, `id`, `footer-menu-honestpaws`)
    
    return (
      <>
        <section className="bg-white footer-menu-container">
          <div className="container">
            <nav className="row d-flex flex-column flex-md-row justify-content-around footer-nav-menu" id={ menuId } title="Footer Main Navigation">
              {  menu.menuColumns ?  menu.menuColumns.map((column, index) => {
                return (
                  <div className="menu-column" key={index}>
                    { column.menuItems ? column.menuItems.map((item, index) => {
                      return (
                        <MenuColumnList title={item.label} key={`mcl-${index}`}>
                          { item.menuItems ? item.menuItems.map((innerItem, index) => {
                            return (
                              innerItem.name.match('Social Networks') ? (
                                <li className="icons mt-4" key={index}>
                                  { this.socialNetworks.instagram ? (
                                    <a href={ this.socialNetworks.instagram } rel="noreferrer" target="_blank" className="icon-container" title="Go To Instagram Profile">
                                      <FaInstagram className="icon" title="Instagram Icon" />
                                    </a>
                                  ) : '' }
                                  { this.socialNetworks.facebook ? (
                                    <a href={ this.socialNetworks.facebook } rel="noreferrer" target="_blank" className="icon-container" title="Go To Facebook Profile">
                                      <FaFacebookSquare className="icon" title="Facebook Icon" />
                                    </a>
                                  ) : '' }
                                  { this.socialNetworks.twitter ? (
                                    <a href={ this.socialNetworks.twitter } rel="noreferrer" target="_blank" className="icon-container" title="Go To Twitter Profile">
                                      <FaTwitter className="icon" title="Twitter Icon" />
                                    </a>
                                  ) : '' }
                                  { this.socialNetworks.pinterest ? (
                                    <a href={ this.socialNetworks.pinterest } rel="noreferrer" target="_blank" className="icon-container" title="Go To Pinterest Profile">
                                      <FaPinterest className="icon" title="Pinterest Icon" />
                                    </a>
                                  ) : '' }
                                  { this.socialNetworks.youtube ? (
                                    <a href={ this.socialNetworks.youtube } rel="noreferrer" target="_blank" className="icon-container" title="Go To Youtube Profile">
                                      <FaYoutube className="icon" title="Youtube Icon" />
                                    </a>
                                  ) : '' }
                                </li>
                              ) :
                              // innerItem.name.match('Klaviyo Form') ? (
                              //   <>
                              //     <li key={index} className="d-none d-md-block">
                              //       <div className="text-primary">Sign up for Honest Paws emails:</div>
                              //       <CustomIframe src={`/klaviyo-form?id=${innerItem.label}`} style={{height:'165px'}}/>
                              //     </li>
                              //     <div key={`d-${index}`} className="d-md-none text-center pt-2 klaviyo-footer-form-mobile container">
                              //       <div className="text-primary">Sign up for Honest Paws emails:</div>
                              //       <CustomIframe src={`/klaviyo-form?id=${innerItem.label}`} style={{height:'170px'}}/>
                              //     </div>
                              //   </>
                              // ) :
                              (
                                <li key={index}>
                                  { innerItem.url ? (
                                    isExternalUrlOrHash(innerItem.url) ? (
                                      <a href={ innerItem.url } rel="noreferrer"  target="_blank">{ innerItem.label }</a>
                                    ) : (
                                      <Link to={ slashLink(innerItem.url) }>{ innerItem.label }</Link>
                                    )
                                  ) : '' }
                                </li>
                              )                                
                            )
                          }) : ''}
                        </MenuColumnList>
                      )
                    }) : ''}
                  </div>
                )
              }) : '' }
            </nav>
          </div>
        </section>
        <section className="bg-primary text-white text-center ">
          <div className="disclaimer container col-10 col-md-8 text-left mb-4 p-2">
            FDA DISCLOSURE This product is not for use by or sale to persons under the age of 18. This product should
            be used only as directed on the label. It should not be used if you are pregnant or nursing. Consult with a
            physician before use if you have a serious medical condition or use prescription medications. A Doctor’s
            advice should be sought before using this and any supplemental dietary product. All trademarks and
            copyrights are property of their respective owners and not affiliated with nor do they endorse this product.
            These statements have not been evaluated by the FDA. This product is not intended to diagnose, treat, cure
            or prevent any disease. Individual weight loss results will vary. By using this site you agree to follow the
            Privacy Policy and all Terms & Conditions printed on this site. Void Where Prohibited By Law.
          </div>
          <div className="container">
            <a href="https://www.bbb.org/us/tx/league-city/profile/pet-services/honest-paws-llc-0915-90052088/#sealclick" target="_blank" rel="nofollow">
              <img src="https://seal-houston.bbb.org/seals/gray-seal-200-42-whitetxt-bbb-90052088.png" alt="Honest Paws LLC BBB Business Review" className="mb-3" loading="lazy"/>
            </a>
            <a href="https://www.youracclaim.com/badges/c1d5d936-6a1e-4d72-800e-4f191867c307/public_url" target="_blank" rel="nofollow">
              <img src="/img/badges/nha-silver-member.png" alt="NHA Silver Member" loading="lazy"/>
            </a>
            <nav className="mt-3" title="Footer Secondary Navigation">
              <Link to="/privacy-policy/" className="mr-3 text-white">Privacy Policy</Link>
              <Link to="/terms-and-conditions/" className="text-white">Terms of Service</Link>
            </nav>
            <p className="mt-2 mb-0">
              &copy;{ (new Date()).getFullYear() } Honest Paws, LLC. <br className="d-md-none" />All rights reserved
            </p>
          </div>
        </section>
      </>
    )
  }
  
  /**
   * Renders logo menu
   * @return {HTMLDomElement}
   */
  renderLogoMenu() {
    const { logo } = this.props
    return (      
      <nav className="bg-primary footer-nav-menu" id='footer-logo-menu' title="Footer Main Navigation">
        <section className="container pb-0">
          <div className="footer-logo col-12 text-center">
            { this.props.hideLinkOnLogo ? (
              <div>
                { logo instanceof Object === true && 'childImageSharp' in logo ? (
                  <Img fixed={logo.childImageSharp.fixed} alt="Honest Paws Logo" className="img-fluid logo mx-auto mx-lg-0" />
                ) : (
                  <img src={logo} alt="Honest Paws Logo" className="img-fluid logo mx-auto mx-lg-0" />
                )}
              </div>
            ) : (
              <Link to="/" title="Go To Homepage">
                { logo instanceof Object === true && 'childImageSharp' in logo ? (
                  <Img fixed={logo.childImageSharp.fixed} alt="Honest Paws Homepage" className="img-fluid logo mx-auto mx-lg-0" />
                ) : (
                  <img src={logo} alt="Honest Paws Homepage" className="img-fluid logo mx-auto mx-lg-0" />
                )}
              </Link>
            )}
          </div>
        </section>
        <div className="clearfix"></div>
        <section className="text-white text-center pt-3">
          <div className="container">
            { !this.props.hideLinks &&
              <nav title="Footer Secondary Navigation">
                <Link to="/privacy-policy/" className="mr-3 text-white" target="_blank">Privacy Policy</Link>
                <Link to="/terms-and-conditions/" className="text-white" target="_blank">Terms of Service</Link>
              </nav>
            }
            <p className="mt-2 mb-0">
              &copy;{ (new Date()).getFullYear() } Honest Paws, LLC. <br className="d-md-none" />All rights reserved
            </p>
          </div>
        </section>
      </nav>
    )
  }
  
  render() {
    return this.props.menu ? this.renderMenuFromContentful(this.props.menu) : this.renderLogoMenu()    
  }
}

export default (props) => { 
  return (
    <FooterNavigation  {...props} />
  )
};
