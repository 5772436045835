import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import styles from './menu-column-list.scss'


class MenuColumnList extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = { open: false };
  }

  toggleMenu() {
    this.setState(state => ({
      open: !state.open
    }));
  }
  
  render() {
    const { children, className, title } = this.props
    
    const columnClassName = `menu-container ${ className || '' }`
    
    return (
      <div className={ `${columnClassName} ${this.state.open ? 'open': ''}` }>
        <ul>
          <li className="column-title" onClick={this.toggleMenu}>{ title }</li>
          { children }
        </ul>
      </div>
    )
  }
}

export default MenuColumnList
