import React from 'react'
import styles from './table-of-contents.scss'
import { createTableOfContentsStructureFromContentful, handleInnerNavigation, getHeaderSize } from "../../helpers.js"

class TableOfContentsV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionsVisibles: 7,
      sectionsPerPage: 8,
      showMoreButton: true
    }

    this.anchorsOffset = 0
    this.nodesTree = this.props.nodesTree || createTableOfContentsStructureFromContentful(this.props.nodes)
    this.theme = this.props.theme
    this.titleTableOfContents = this.props.titleTableOfContents
    this.showMoreSections = this.showMoreSections.bind(this)
    this.handleSectionsVisible = this.handleSectionsVisible.bind(this)
    this.calculateAnchorOffset = this.calculateAnchorOffset.bind(this)

  }

  calculateAnchorOffset() {
    const headerSize = getHeaderSize()
    this.anchorsOffset = headerSize.height + 10 // +10px in order to improve UX
  }

  /**
   * Renders ToC tree recursively
   * @param  {Array} nodes
   * @return {Node}
   */
  renderTree(nodes) {
    return (
      <div className="row m-0">
        { nodes.slice(0, this.state.sectionsVisibles).map((node, index) => {
          return (
            <div className="option" key={index}>
              <div className="row-text">
                <a href={`#h-${node.id}`} onClick={(e) => handleInnerNavigation(e, -this.anchorsOffset)}>{node.label}</a>
                {
                  node.children && node.children.length ? this.renderTree(node.children) : ''
                }
              </div>
            </div>
          )
        }) }
        <div className={`row-text ${!this.state.showMoreButton ? "d-none" : ""}`}>
          <a onClick={this.showMoreSections}>More...</a>
        </div>
      </div>
    )
  }

  /**
   * Displays More Sections
   */
  showMoreSections () {
    this.setState({
      sectionsVisibles: this.state.sectionsVisibles + this.state.sectionsPerPage,
      showMoreButton: this.nodesTree.length > (this.state.sectionsVisibles + this.state.sectionsPerPage)
    })
  }

  componentDidMount() {
    this.setState({
      showMoreButton: this.nodesTree.length > this.state.sectionsVisibles
    })
    setTimeout(() => {
      this.handleSectionsVisible()
    }, 500);
    this.calculateAnchorOffset()
  }

  handleSectionsVisible() {
    var windowWidth = window.innerWidth

    if (windowWidth < 768) {
      this.setState({
        sectionsVisibles: 5,
        sectionsPerPage: 6
      })
    }
  }

  render() {
    return (
      <div className={`toc toc-v2 ${this.props.className ? this.props.className : ''}`}>
        <div className={`toc-content toc-content-v2`}>
          { this.nodesTree ? this.renderTree(this.nodesTree) : ''}
        </div>
      </div>
    )
  }
}

export default TableOfContentsV2
