import React from 'react'

class Iframe extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const attrs = {
      src: this.props.src,
      frameBorder: "0",
      style: this.props.style || '',
      className: this.props.className || ''
    }
    return (<iframe { ...attrs }></iframe>)
  }
}

export default Iframe